import Vue from 'vue';
import { ValidationProvider, ValidationObserver, setInteractionMode, localize, extend } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import * as rules from 'vee-validate/dist/rules';
import axios from '@/js/lib/axios';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

setInteractionMode('eager');

ja.messages = {
  ...ja.messages,
  // 型エラー回避のためにスプレッド構文で結合する
  // eslint-disable-next-line unicorn/no-useless-spread
  ...{
    tel: '電話番号が正しくありません',
    email: 'メールアドレスが正しくありません',
    zip: '郵便番号が正しくありません',
    shop_alias: '使用できない文字が含まれています',
    shop_alias_duplicate_check: 'このショップURLはすでに使用されています',
    account_number: '半角数字7桁で入力して下さい',
    account_name: '全角カナ・アルファベット・数字で入力して下さい',
  },
};
localize('ja', ja);

export const validateZipcode = (value: string) => /^\d{3}-?\d{4}$/.test(value);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
extend('alpha_num', {
  validate(value) {
    return /^[\dA-Za-z]+$/.test(value);
  },
});
extend('tel', {
  validate(value) {
    return /^0[\d-]{9,}$/.test(value);
  },
});
extend('email', {
  validate(value) {
    return /^[\w+.-]+@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/.test(value);
  },
});
extend('zip', {
  validate(value) {
    return validateZipcode(value);
  },
});
extend('shop_alias', {
  validate(value) {
    return /^[\w-]+$/.test(value);
  },
});
extend('shop_alias_duplicate_check', {
  async validate(value) {
    if (!/^[\w-]+$/.test(value)) {
      return true;
    }
    const res = await axios.get(`/api/validate/unique-shop-alias/shop%5Balias%5D?shop%5Balias%5D=${value}`);
    return res.data === true;
  },
});
extend('account_number', {
  validate(value) {
    return /^\d{7}$/.test(value);
  },
});
extend('account_name', {
  validate(value) {
    // eslint-disable-next-line no-irregular-whitespace
    return /^[\d A-Za-z―　「」ァ-ヶ・ー（），－．／０-９Ａ-Ｚａ-ｚ￥]*$/.test(value);
  },
});
