// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IModal__content--Rm5nd{text-align:center}.IModal__withActionButton--Ktt17{display:flex;flex-direction:column;max-height:calc(100vh - 100px)}.IModal__withActionButton--Ktt17 .IModal__content--Rm5nd{overflow-y:auto}.IModal__withActionButton--Ktt17 .IModal__buttonArea--Tfmp2{display:grid;gap:15px;grid-template-columns:1fr;margin-top:30px}.IModal__withActionButton--Ktt17 .IModal__buttonArea--Tfmp2.IModal__hasCancel--pbuo0{grid-template-columns:1fr 2fr}.IModal__dialog--tavPs.IModal__loading--RhUln .el-dialog{background-color:transparent;box-shadow:none}", "",{"version":3,"sources":["webpack://./assets/src/js/components/molecules/IModal.vue"],"names":[],"mappings":"AAEA,wBACE,iBAAA,CAGF,iCACE,YAAA,CACA,qBAAA,CACA,8BAAA,CAEA,yDACE,eAAA,CAGF,4DACE,YAAA,CACA,QAAA,CACA,yBAAA,CACA,eAAA,CAEA,qFACE,6BAAA,CAOJ,yDACE,4BAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "IModal__content--Rm5nd",
	"withActionButton": "IModal__withActionButton--Ktt17",
	"buttonArea": "IModal__buttonArea--Tfmp2",
	"hasCancel": "IModal__hasCancel--pbuo0",
	"dialog": "IModal__dialog--tavPs",
	"loading": "IModal__loading--RhUln"
};
module.exports = ___CSS_LOADER_EXPORT___;
