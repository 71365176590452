import { readonly, ref } from 'vue';
import { defineStore } from 'pinia';
import { pinia } from './';
import { useUserStore } from './user';
import { countItemLikes, isItemLiking } from '@/js/lib/api/item';

const userStore = useUserStore(pinia);

export const useItemStore = defineStore('item', () => {
  const likedCounts = ref<{
    [key: string]: number;
  }>({});
  const likedItemIds = ref<number[]>([]);
  async function initLikedData(itemIds: number[]) {
    likedCounts.value = await countItemLikes({ itemIds });
    if (userStore.isAuthenticated) {
      const likedList = await isItemLiking({ itemIds });
      likedItemIds.value = likedList.filter((item) => item.liked === 1).map((item) => Number(item.itemId));
    }
  }
  function addLikedItemId(itemId: number) {
    likedCounts.value = likedCounts.value[itemId] ? { ...likedCounts.value, [itemId]: likedCounts.value[itemId] + 1 } : { ...likedCounts.value, [itemId]: 1 };
    if (!likedItemIds.value.includes(itemId)) {
      likedItemIds.value.push(itemId);
    }
  }
  function removeLikedItemId(itemId: number) {
    likedCounts.value = { ...likedCounts.value, [itemId]: likedCounts.value[itemId] - 1 };
    likedItemIds.value.splice(likedItemIds.value.indexOf(itemId), 1);
  }

  return { likedCounts: readonly(likedCounts), likedItemIds: readonly(likedItemIds), initLikedData, addLikedItemId, removeLikedItemId };
});
