// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmailConfirmedModal__content--EnJE6{font-size:16px;text-align:center}.EmailConfirmedModal__title--adoMT{font-size:24px;font-weight:700;margin:20px 0}.EmailConfirmedModal__lead--zJixK{display:inline-block;font-size:16px;margin:0 0 15px;text-align:left}@media only screen and (max-width:639px){.EmailConfirmedModal__icon--tHqSy{height:auto;width:66px}.EmailConfirmedModal__title--adoMT{font-size:18px}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/organisms/modals/EmailConfirmedModal.vue"],"names":[],"mappings":"AAEA,qCACE,cAAA,CACA,iBAAA,CAUF,mCACE,cAAA,CACA,eAAA,CACA,aAAA,CAOF,kCACE,oBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CApBA,yCADF,kCAEI,WAAA,CACA,UAAA,CAIJ,mCAMI,cAAA,CAVA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "EmailConfirmedModal__content--EnJE6",
	"title": "EmailConfirmedModal__title--adoMT",
	"lead": "EmailConfirmedModal__lead--zJixK",
	"icon": "EmailConfirmedModal__icon--tHqSy"
};
module.exports = ___CSS_LOADER_EXPORT___;
