import { filesize } from 'filesize';
import dayjs from 'dayjs';
import validate from 'validate.js';

type ValidateJSWithOptions = typeof validate & { options: { fullMessages: boolean }; async: { options: { fullMessages: boolean } } };

(validate as ValidateJSWithOptions).options = { fullMessages: false };
(validate as ValidateJSWithOptions).async.options = { fullMessages: false };

validate.validators.email.message = '正しいメールアドレスを入力して下さい。';
validate.validators.length.tooLong = '%{count}文字以内で入力して下さい。';
validate.validators.length.tooShort = '%{count}文字以上で入力して下さい。';
validate.validators.numericality.notInteger = '数値を入力して下さい。';
validate.validators.numericality.notGreaterThanOrEqualTo = '%{count}以上で入力して下さい。';
validate.validators.numericality.notLessThanOrEqualTo = '%{count}以下で入力して下さい。';
validate.validators.numericality.notValid = '数値を入力して下さい。';
validate.validators.presence.options = { allowEmpty: false, message: '入力してください。' };
validate.validators.url.options = { message: 'URLを正しく入力してください。' };

// https://validatejs.org/#validators-datetime
validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse(value) {
    let v = value;
    if (!(v instanceof Date)) {
      v = new Date(v);
    }
    return v.getTime();
  },
  // Input is a unix timestamp
  format(value, options) {
    const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    const datetime = dayjs(value);
    return datetime.format(format);
  },
});

/*
    value = { year: 2018, monthIndex: 0, date: 30 } // 2018-1-30
*/
validate.validators.dateWithSelect = (value, options) => {
  const date = new Date(Date.UTC(value.year, value.monthIndex, value.date));
  return validate.validators.date(date, options);
};

validate.validators.imageFile = (file, options) => {
  const acceptTypes = options.types || ['jpeg', 'png', 'gif'];
  const maxSize = options.maxSize || 10240000; // 10MB
  const re = new RegExp(`^image/(${acceptTypes.join('|')})$`);

  if (file) {
    if (!re.test(file.type)) {
      const typesUppercase = acceptTypes.map((type) => type.toUpperCase());
      return `${typesUppercase.join('、')}フォーマットの画像を選択してください。`;
    }

    if (file.size > maxSize) {
      const filesizeStr = filesize(maxSize, { round: 0, standard: 'jedec' });
      if (typeof filesizeStr === 'string') {
        return `${filesizeStr.replace(' ', '')}以内の画像を選択してください。`;
      }
    }
  }

  return '';
};

validate.validators.atLeastOne = (_value, options, _key, attributes) => {
  const { keys } = options;

  for (const key of keys) {
    if (attributes[key]) {
      return '';
    }
  }

  return options.message || '入力してください。';
};

export default validate;

// https://github.com/ansman/validate.js/issues/192#issuecomment-274638665
export const ignoreEmptyValueConstraint = (options) => (value) => {
  if (validate.isEmpty(value)) {
    return false; // don't validate empty values
  }
  return options; // use the provided constraint options otherwise
};

// 最初のerrorクラス（CSS Modules）までスクロールする
export const scrollToError = () => {
  const firstErrorEl = document.querySelector('[class*="__error--"]');
  if (firstErrorEl) {
    firstErrorEl.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};
