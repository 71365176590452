// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BasicDialog__wrapper--rLI_V{display:inline-block}.BasicDialog__underline--tA7Tc{border-bottom:1px dashed #121212}", "",{"version":3,"sources":["webpack://./assets/src/js/components/organisms/BasicDialog.vue"],"names":[],"mappings":"AAEA,6BACE,oBAAA,CAGF,+BACE,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "BasicDialog__wrapper--rLI_V",
	"underline": "BasicDialog__underline--tA7Tc"
};
module.exports = ___CSS_LOADER_EXPORT___;
