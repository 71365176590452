import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useUserCredentialStore = defineStore('userCredential', () => {
  const credentialsArr = ref<string[]>([]);
  const hasCredential = computed(() => (credential: string) => credentialsArr.value.includes(credential));

  function updateCredential(credentials: string[]) {
    credentials.forEach((credential) => {
      if (!credentialsArr.value.includes(credential)) {
        credentialsArr.value.push(credential);
      }
    });
  }

  function resetCredential() {
    credentialsArr.value = [];
  }

  return { hasCredential, updateCredential, resetCredential };
});
