import '@/js/dist/www.iichi.com/common';

import Vue from 'vue';
import { linkify } from '@/js/lib/helper/linkify';

import common from '@/js/lib/common';
import { addHistoryItem } from '@/js/lib/api/item';
import LikeButton from '@/js/components/www.iichi.com/pages/listing/item/likeButton.vue';
import { addFollowButton, addFollowRecommendIfItemLiked } from '@/js/lib/ui/follow-button';
import SameCategoryItems from '@/js/components/listing/sameCategoryItems.vue';
import CartButton from '@/js/components/listing/cart-button.vue';
import PhotoViewer from '@/js/components/www.iichi.com/organisms/PhotoViewer.vue';
import IMenu from '@/js/components/molecules/IMenu.vue';
import SocialShare from '@/js/components/common/ui/share.vue';
import MessageButton from '@/js/components/listing/MessageButton.vue';
import SendRequestButton from '@/js/components/listing/SendRequestButton.vue';
import SendRequestFinished from '@/js/components/listing/SendRequestFinished.vue';

document.addEventListener('DOMContentLoaded', async () => {
  if ('customElements' in window) {
    new Vue({
      el: '.photo-viewer',
      components: {
        PhotoViewer,
      },
    });
  }

  new Vue({
    el: '#same-category-items',
    components: {
      SameCategoryItems,
    },
  });

  if ('IntersectionObserver' in window) {
    const cartButtonEl = document.querySelector('.cart-button');
    const breadEl = document.querySelector('#bread');
    const footerMetaEl = document.querySelector('#footer-meta');
    const cartButtonObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            cartButtonEl.classList.remove('is-fixed');
          } else {
            cartButtonEl.classList.add('is-fixed');
          }
        });
      },
      {
        rootMargin: '20px 0px 600px 0px',
      },
    );
    cartButtonObserver.observe(footerMetaEl || breadEl); // アプリの場合はパンくずを監視する
    cartButtonObserver.observe(cartButtonEl); // 「カートに入れる」ボタンを後に監視する必要がある
  }

  new Vue({
    el: 'cart-button',
    components: {
      CartButton,
    },
  });

  new Vue({
    el: document.querySelector('social-share'),
    components: {
      SocialShare,
    },
  });

  if (document.querySelector('message-button')) {
    new Vue({
      el: 'message-button',
      components: {
        MessageButton,
      },
    });
  }

  const descriptionEl = document.querySelector('#item-description .text');
  descriptionEl.innerHTML = linkify(descriptionEl.innerHTML, true);

  const shippingMenuEls = document.querySelectorAll('i-menu');
  shippingMenuEls.forEach((el) => {
    new Vue({
      el,
      components: {
        IMenu,
      },
    });
  });

  if (document.querySelector('#item-like > .button')) {
    new Vue({
      el: '#item-like > .button',
      components: {
        LikeButton,
      },
    });
  }

  await addFollowButton();
  addFollowRecommendIfItemLiked();

  addHistoryItem({ itemId: common.env.Pallet.Item.itemId });

  const sendRequestButtonEl = document.querySelector('send-request-button');

  if (sendRequestButtonEl) {
    new Vue({
      el: sendRequestButtonEl,
      components: {
        SendRequestButton,
      },
    });
  }

  const sendRequestFinishedEl = document.querySelector('send-request-finished');

  if (sendRequestFinishedEl) {
    new Vue({
      el: sendRequestFinishedEl,
      components: {
        SendRequestFinished,
      },
    });
  }
});
