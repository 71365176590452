// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmailConfirmModal__wrapper--Lp52U{background-color:#fff;line-height:1.5;padding:20px 0 0;text-align:center}.EmailConfirmModal__title--M36St{font-size:24px;font-weight:700;margin:20px 0}.EmailConfirmModal__lead--LUcXj{margin:0 0 15px;text-align:left}.EmailConfirmModal__lead--LUcXj,.EmailConfirmModal__link--jX5qw{font-size:16px}.EmailConfirmModal__link--jX5qw{text-decoration:underline}@media only screen and (max-width:639px){.EmailConfirmModal__icon--RE5sE{height:auto;width:70px}.EmailConfirmModal__title--M36St{font-size:18px}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/organisms/modals/EmailConfirmModal.vue","webpack://./assets/src/scss/base/_variables.scss"],"names":[],"mappings":"AAEA,mCACE,qBC+BM,CD9BN,eAAA,CACA,gBAAA,CACA,iBAAA,CAUF,iCACE,cAAA,CACA,eAAA,CACA,aAAA,CAOF,gCAEE,eAAA,CACA,eAAA,CAGF,gEALE,cAOA,CAFF,gCAEE,yBAAA,CAxBA,yCADF,gCAEI,WAAA,CACA,UAAA,CAIJ,iCAMI,cAAA,CAVA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "EmailConfirmModal__wrapper--Lp52U",
	"title": "EmailConfirmModal__title--M36St",
	"lead": "EmailConfirmModal__lead--LUcXj",
	"link": "EmailConfirmModal__link--jX5qw",
	"icon": "EmailConfirmModal__icon--RE5sE"
};
module.exports = ___CSS_LOADER_EXPORT___;
