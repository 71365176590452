// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".followRecommendIfItemLiked__followButton--fsefL{margin-top:6px}@media only screen and (max-width:639px){.followRecommendIfItemLiked__followButton--fsefL{margin-top:12px}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/www.iichi.com/organisms/followRecommendIfItemLiked.vue"],"names":[],"mappings":"AAEA,iDACE,cAAA,CAEA,yCAHF,iDAII,eAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"followButton": "followRecommendIfItemLiked__followButton--fsefL"
};
module.exports = ___CSS_LOADER_EXPORT___;
