// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingModal__content--dH0cQ{text-align:center}.LoadingModal__content--dH0cQ .LoadingModal__image--UT82R{vertical-align:bottom}.LoadingModal__loadingText--juDpZ{margin:0;padding-top:8px}", "",{"version":3,"sources":["webpack://./assets/src/js/components/organisms/modals/LoadingModal.vue"],"names":[],"mappings":"AAEA,8BACE,iBAAA,CAEA,0DACE,qBAAA,CAIJ,kCACE,QAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "LoadingModal__content--dH0cQ",
	"image": "LoadingModal__image--UT82R",
	"loadingText": "LoadingModal__loadingText--juDpZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
