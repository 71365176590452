import { readonly, ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { getBaskets, type CartBasket } from '@/js/lib/api/cart';
import { sum } from '@/js/lib/helper/array';

export const useCartStore = defineStore('cart', () => {
  const baskets = ref<CartBasket[]>([]);
  const itemCount = computed(() => {
    const quantities: number[] = baskets.value.map((basket) => sum(basket.items.map((cartItem) => cartItem.quantity)));

    return sum(quantities);
  });
  async function loadBaskets() {
    baskets.value = await getBaskets();
  }

  return { baskets: readonly(baskets), itemCount, loadBaskets };
});
